export const DEPOSIT_SUCCESSFUL_MESSAGE_TITLE = 'Deposit successful!';

export const RECURRING_DEPOSIT_SUCCESSFUL_MESSAGE_TITLE = 'Recurring deposits enabled!';

export const RECURRING_DEPOSIT_CANCEL_MESSAGE_SUBTITLE = (account: string) =>
  `Once you cancel, deposits will no longer be made automatically from your checking account ending 
   in ${account} and pending deposits that are not yet in progress will be canceled. To enable automatic payments you
   will need to set up recurring deposit again.`;

export const RECURRING_NON_VERIFIED_DEPOSIT_SUCCESSFUL_MESSAGE_DESCRIPTION = (amount: string) =>
  `Once your account has been verified, your first deposit of ${amount} should arrive in the next 48 hours, though it 
   might take up to 4 days in some cases.`;

export const DEPOSIT_NON_VERIFIED_ACCOUNT_SUCCESSFUL_MESSAGE_DESCRIPTION =
  'Once your account has been verified, we’ll process ' +
  'your transfer and update your portfolio in the next 48 hours, though it ' +
  'might take up to 4 days in some cases';

export const DEPOSIT_NON_VERIFIED_ACCOUNT_SUCCESSFUL_MESSAGE = `
  ${DEPOSIT_SUCCESSFUL_MESSAGE_TITLE} ${DEPOSIT_NON_VERIFIED_ACCOUNT_SUCCESSFUL_MESSAGE_DESCRIPTION}
`;

export const WITHDRAWAL_SUCCESSFUL_MESSAGE_TITLE = 'Withdrawal successful!';

export const WITHDRAWAL_SUCCESSFUL_MESSAGE_DESCRIPTION =
  'Please note, it could take up to 5 business days for the money to be in your account';

export const WITHDRAWAL_SUCCESSFUL_MESSAGE = `
  ${WITHDRAWAL_SUCCESSFUL_MESSAGE_TITLE} ${WITHDRAWAL_SUCCESSFUL_MESSAGE_DESCRIPTION}
`;

export const ACCOUNT_CLOSURE_MESSAGE =
  'Your investment account is now closed/limited in functionality. If you ' +
  'have any remaining funds on your account they will be transferred to ' +
  'your connected bank account.';

/* @deprecated remove */
export const VERIFY_EMAIL_MESSAGE =
  'Welcome amazing {orgName} user! To have full access to your account please ' + 'first verify your email.';

export const ACCOUNT_REJECTED_MESSAGE = `Your account application has been rejected. 
If you have any questions or need assistance, feel free to reach out to hello@eko.investments. 
We're here to help you.`;
