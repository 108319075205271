import { getPrivateAPI, privateAPIv2 } from '~/api/base';
import { response } from '@api/helpers';
import { ACHRelationship, ACHRelationshipDTO } from '@models/transfers';
import { numberStringDtoMapper } from '@utils/dtoMappers';

import { getPrefilledDataFromUrl } from './utils';

export default {
  fetchAccounts: (portfolioId: string) =>
    response(privateAPIv2.get(`/portfolios/${portfolioId}/bank-accounts/available/`)).then(
      (accounts: ACHRelationshipDTO[]) =>
        accounts.map(
          (account): ACHRelationship => ({
            id: account.id,
            displayName: account.display_name,
            type: account.type,
            lastDigits: account.last_digits,
            connectionType: account.connection_type,
            balance: numberStringDtoMapper(account.balance),
          }),
        ),
    ),
  fetchBrokerData: async () => {
    const prefilledDataFromUrl = getPrefilledDataFromUrl();
    const dataPromise = prefilledDataFromUrl
      ? prefilledDataFromUrl
      : getPrivateAPI({ retries: 5 }).get('platform/broker-data/');

    return await dataPromise.then((data) => {
      const newData = { ...data };
      const mapPhoneNumber = (phone?: string) =>
        phone && phone[0] !== '+' && phone.length === 10 ? `+1${phone}` : phone;

      if (newData.data && newData.data.contact && newData.data.contact.phone_number) {
        newData.data.contact.phone_number = mapPhoneNumber(newData.data.contact.phone_number);
      }
      return newData;
    });
  },
};
