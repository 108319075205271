export const getPrefilledDataFromUrl = () => {
  let data: Record<'broker_data', any> | undefined = undefined;

  try {
    const queryParams = new URLSearchParams(window.location.search);
    const queryData = queryParams.get('data');
    if (queryData) {
      data = JSON.parse(atob(queryData));
    }
  } catch (_) {
    console.error('Error while parsing query data parameter');
  }

  if (data) {
    return Promise.resolve({ data: data.broker_data });
  }

  return;
};
